function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  
function isInStandaloneMode() {
    return ('standalone' in window.navigator) && (window.navigator.standalone);
}


export default {
    isIOS,
    isInStandaloneMode
}