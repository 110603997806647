import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token'),
    title: '',
    showAddScreen: false,
    isPwaInstalled: false,
    showLogin: false,
  },
  mutations: {
    clear(state, val) {
      state.token = val
      sessionStorage.setItem('token', val)
    },
    recordScroll(state, n) {
      state.scrollTop = n
    },
    setShowAddScreen(state, val) {
      console.log('setShowAddScreen', val);
      
      state.showAddScreen = val
    },
    setIsPwaInstalled(state, val) {
      console.log('setShowAddScreen', val);
      
      state.isPwaInstalled = val
    },
    setShowLogin(state, val) {
      state.showLogin = val;
    }
  },
  modules: {
    user
  }
})

export default store
